<template>
  <div class="wrapper position-relative">
    <img src="./images/sample_bg_01.jpg" alt="" width="100%" />
    <img src="./images/sample_bg_02.jpg" alt="" width="100%" />
    <img src="./images/sample_bg_03.jpg" alt="" width="100%" />
    <img src="./images/sample_bg_04.jpg" alt="" width="100%" />
    <img src="./images/sample_bg_05.jpg" alt="" width="100%" />

    <div class="next-btn" @click="handleNext"></div>
  </div>
</template>

<script>import { mapActions } from "vuex";

export default {
  created() {
    document.title = "免費索樣活動網站｜Huggies好奇";
  },
  methods: {
    ...mapActions({
      setLoading: "liffHuggies/setLoading",
    }),
    handleNext() {
      this.setLoading(true);
      this.$router.push({
        name: "LiffHuggiesSample",
      }).then(() => {
        this.setLoading(false);
      });
    },
  },
};
</script>

<style lang="scss">
.wrapper {
  width: 100%;
  max-width: 576px;
  margin: auto;
}

.bg-2 {
  position: absolute;
  top: 240px;
    width: 95%;
    left: 15px;

  @media screen and (max-width: 576px) {
    top: 42vw;
    width: 95%;
    left: 2vw;
  }
}

.bg-3 {
  position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
}

.next-btn {
  background: url("./images/sample_btn.png") no-repeat;
  background-size: contain;
  width: 40vw;
  position: absolute;
  height: 70px;
  top: 368px;
  left: 49px;

  @media screen and (max-width: 576px) {
    height: 12vw;
    top: 64vw;
    left: 9vw;
  }
}
</style>
