<template>
  <div>
    <div v-if="isLoading" class="load-overlay">
      <div class="load"></div>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      isLoading: "liffHuggies/isLoading",
    }),
  },
  created() {
    document.title = "免費索樣活動網站｜Huggies好奇";
  }
}
</script>

<style lang="scss">
.load {
  z-index: 10001;
  position: fixed;
  top: 20%;
  left: 25%;
  height: 50px;
  width: 50%;
  background-image: url(./images/load.gif);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.load-overlay {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
}
</style>
