<template>
  <div class="container">
    <b-card-group deck>
      <b-card header="HUGGIES好奇裸感好動褲派樣包申請活動" header-tag="header">
        <b-card-text>
          謝謝您申請好奇裸感好動褲派樣包，請務必填寫完成表單資料，並確認收到LINE成功申請訊息
        </b-card-text>
      </b-card>
    </b-card-group>
    <div class="text-center">
      <b-button
        class="mb-2"
        style="background: #5c7580; color: #fff"
        size="lg"
        href="https://line.me/R/ti/p/@huggiestw"
        >回好奇 Line 首頁</b-button
      >
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
.container {
  padding: 20px;
}
.red {
  color: #dc3545;
  font-weight: normal;
}
.card {
  border-radius: 10px;
  margin-bottom: 15px;
}
.card .card-header {
  background: #5c7580;
  color: #fff;
  padding: 20px;
  font-size: 22px;
  text-align: left;
  border-radius: 10px 10px 0 0;
  border-bottom: 0;
}
.card .card-body {
  padding: 16px 20px;
  text-align: left;
  font-size: 14px;
  color: #9e9fa1;

  border-radius: 0 0 10px 10px;
}

.card-deck .card .card-body {
  border: solid 1px #5c7580;
}

.form-group .col-form-label {
  color: #666;
  font-weight: bold;
  font-size: 16px;
}

.form-text {
  font-size: 14px;
  margin-bottom: 14px;
}
.form-control {
  font-size: 14px;
}

.custom-radio label {
  line-height: 1.5;
}
</style>
